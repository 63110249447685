.daojo-background {
    background-image:   linear-gradient(
            rgba(0, 0, 0, 0.5),
            #22242b,
            #22242b
    ),  url("./assets/images/daojo-background-light.jpg");
    background-repeat: no-repeat;
    background-size: 180%;
    background-position: top center;
}

@media screen and (min-width: 501px) {
    .daojo-background {
        background-image:   linear-gradient(
                rgba(0, 0, 0, 0.6),
                rgba(0, 0, 0, 0.6),
                #22242b
        ),  url("./assets/images/daojo-background-light.jpg");
        background-size: cover;
    }
}

/*
    height: 110vh;

 */