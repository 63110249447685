@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;700&display=swap');

* {
    margin: 0;
    padding: 0;
}

html, body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #22242b;
    scroll-behavior: smooth;
}

/*     background-color: #22242b; */

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    color: #ed1c24;
}

a:visited {
    color: #ed1c24;
}

a:hover {
    color: #ff0000;
}

a:active {
    color: #ff0000;
}