/***************
  STEALTH
****************/

.stealth {
    background-color: #000;
    background-image: url('../assets/images/mat-background.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.stealth_shh {
    z-index: 2;
    background-image: url('../assets/images/its-a-secret-girl.svg');
    background-position: right -1vw bottom;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.stealth_text {

    background-image: url('../assets/images/text-its-a-secret.svg');
    background-position: left center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 30vh;

}
