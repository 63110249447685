.splash-gameplay {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.splash-dimmer {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.splash-logo {
    object-fit: contain;
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    padding-right: 10%;
    padding-left: 10%;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 400px;
}

.splash-buttons {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);

}
