.roadmap {
    background-color: #bb151b;
    color: #fff;
    text-align: left;
    padding: 10% 10% 10% 10%;
    background-image: url("./assets/images/pentagramagon.svg");
    background-repeat: no-repeat;
    background-size: auto 120%;
    background-position: right -100vmin top;
}

@media only screen and (max-width: 960px) {
    .roadmap {
        background-image: unset;
    }
}

.items {
}

.items li:not(:last-child) {
    margin-bottom: 20px;
}


.items a {
    color: yellow;
}

.items a:visited {
    color: yellow;
}

.items a:hover {
    color: hsl(60, 100%, 80%);
}

.items a:active {
    color: hsl(60, 100%, 80%);
}