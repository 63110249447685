.App {
    text-align: center;
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.video {
    margin-left: auto;
    margin-right: auto;
    display: block
}

.row {
    width: 100%;
    margin: 0 auto;
    max-width: 888.5em;
}