.gameplay-demo {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 100vh;

}

.gameplay-video iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%);

}

.gameplay-filter iframe {
    filter: blur(2.5px) brightness(.7) saturate(1.5);
}


@media (min-aspect-ratio: 16/9) {
    .gameplay-video iframe {
        /* height = 100 * (9 / 16) = 56.25 */
        height: 56.25vw;
    }
}
@media (max-aspect-ratio: 16/9) {
    .gameplay-video iframe {
        /* width = 100 / (9 / 16) = 177.777777 */
        width: 177.78vh;
    }
}

@media screen and (max-width: 768px) {
    .gameplay-video iframe {
        left: 100%;
    }
}

.gameplay-controls {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.gameplay-dimmer {
    background-color: rgba(0, 0, 0, 0.65);

}

.gameplay-link {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
