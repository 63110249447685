.contact {
    height: 100vh;
    background-color: #000;
    background-image: linear-gradient(
            rgba(0, 0, 0, 1),
            rgba(0, 0, 0, 0)
    ), url("./assets/images/penta-mat-background.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
